import type {SSPGetRouteTemplateResponse} from './types';
import {isBrowser} from './helpers/browserHelpers';
import debugLib from './debug';

const debug = debugLib('dataForRoutes');

/**
 * Method for development
 */

export const getLocalTemplateVersion = (lang: string): SSPGetRouteTemplateResponse => {
  const definition = {
    id: '',
    name: '',
    description: '',
    previewURLs: [],
    routeDefinitions: [],
    componentDefinitions: [],
    cssDefinition: '',
    i18nDefinition: {},
  };

  if (!isBrowser()) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const fs = require('fs');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const path = require('path');
    const templateDir = path.resolve(process.env.LOCAL_TEMPLATE_PATH || '');

    let dir;
    try {
      dir = fs.readdirSync(path.resolve(templateDir));

      definition.routeDefinitions = fs
        .readdirSync(path.resolve(templateDir, 'routes'))
        .filter(isNotDSStore)
        .map((routename: string) => ({
          jsxDefinition: fs.readFileSync(
            path.resolve(templateDir, 'routes', routename, 'index.jsx'),
            'utf-8'
          ),
          type: routename,
          name: '',
          cssDefinition: fs.readFileSync(
            path.resolve(templateDir, 'routes', routename, 'styles.css'),
            'utf-8'
          ),
        }));
      definition.componentDefinitions = fs
        .readdirSync(path.resolve(templateDir, 'components'))
        .filter(isNotDSStore)
        .map((componentName: string) => {
          const cssDefinition = fs.readFileSync(
            path.resolve(templateDir, 'components', componentName, 'styles.css'),
            'utf-8'
          );

          return {
            ...(cssDefinition ? {cssDefinition} : {}),
            id: componentName.toLowerCase(),
            jsxDefinition: fs.readFileSync(
              path.resolve(templateDir, 'components', componentName, 'index.jsx'),
              'utf-8'
            ),
            name: componentName,
          };
        });
      definition.cssDefinition = fs.readFileSync(
        path.resolve(templateDir, 'global-styles.css'),
        'utf-8'
      );

      try {
        definition.i18nDefinition = JSON.parse(
          fs.readFileSync(path.resolve(templateDir, 'i18n', `${lang}.json`), 'utf-8')
        );
      } catch {
        debug('no i18n definitions');
      }
    } catch (e) {
      debug(e, 'failed to render LOCAL theme', process.env.LOCAL_TEMPLATE_PATH);
    }

    debug('loaded LOCAL theme', dir, definition);
  }

  return definition;
};

export function isNotDSStore(folderName: string) {
  return folderName !== '.DS_Store';
}
