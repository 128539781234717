import {useEffect} from 'react';
import {useUIContext} from 'lib/context';
import type {FC} from 'react';
import type {TemplateDefinition} from '../../lib/types';

type Props = {
  currentRoute: string;
  currentLang: string;
  onTemplateDefinitionsChange: (data: TemplateDefinition) => void;
};

type UpdateTemplateDefinitionPayload = {
  componentsDefinitions: TemplateDefinition['componentDefinitions'];
  dictionariesDefinitions?: {
    lang: string;
    i18nDefinition: TemplateDefinition['i18nDefinition'];
  }[];
  routesDefinitions?: Pick<
    TemplateDefinition['routeDefinitions'][0],
    'id' | 'cssDefinition' | 'jsxDefinition'
  >[];
  templateDefinition: Omit<
    TemplateDefinition,
    'componentDefinitions' | 'i18nDefinition' | 'routeDefinitions'
  >;
};

type IncomingMessage = {
  action: 'UpdateTemplateDefinition';
  payload?: UpdateTemplateDefinitionPayload;
};

const allowedOriginDomain = process.env.SHELF_DOMAIN || 'localhost';

const TemplateDefinitionsListener: FC<Props> = props => {
  const {currentRoute, currentLang, onTemplateDefinitionsChange} = props;
  const {setIsAppInPreviewMode} = useUIContext();

  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent<IncomingMessage>) => {
      if ((event.origin || '').includes(allowedOriginDomain)) {
        const {data} = event;

        if (data.action === 'UpdateTemplateDefinition') {
          const payload = data.payload as UpdateTemplateDefinitionPayload;
          const output = inputTemplatesToOutput(currentRoute, currentLang, payload);

          if (output === null) return;

          setIsAppInPreviewMode(true);

          return onTemplateDefinitionsChange(output);
        }
      }
    };

    const handleBeforeunload = () => {
      window.top?.postMessage({beforeunload: 'beforeunload'}, '*');
    };

    window.top?.postMessage({currentRoute}, '*');

    window.addEventListener('message', handleMessageEvent);

    window.addEventListener('beforeunload', handleBeforeunload);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export function inputTemplatesToOutput(
  currentRoute: string,
  currentLang: string,
  input: UpdateTemplateDefinitionPayload
): TemplateDefinition | null {
  if (!input.routesDefinitions) return null;

  const routeToApply = input.routesDefinitions.find(
    routeDefinition => routeDefinition.id === currentRoute
  );

  if (!routeToApply) return null;

  const i18nDefinition = input.dictionariesDefinitions?.find(item => item.lang === currentLang);

  return {
    ...input.templateDefinition,
    componentDefinitions: input.componentsDefinitions,
    routeDefinitions: [routeToApply] as TemplateDefinition['routeDefinitions'],
    i18nDefinition: i18nDefinition?.i18nDefinition,
    isPreviewed: true,
  };
}

export default TemplateDefinitionsListener;
